import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  TextField,
} from "@mui/material";

export default function UserSystem() {
  const [openMsgBox, setOpenMsgBox] = useState(false);
  const [titleMsgBox, setTitleMsgBox] = useState("");
  const [bodyMsgBox, setBodyMsgBox] = useState("");

  const [reload, setReload] = useState(0);
  const [begdate, setBegdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [lineToken, setLineToken] = useState("");

  const [openDate, setOpenDate] = useState(false);

  async function GetItem() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_system.php",
    })
      .then(function (response) {
        console.log(response);

        setBegdate(response.data[0].begdate);
        setEnddate(response.data[0].enddate);
        setLineToken(response.data[0].line_token);

        //setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function UpdateItem() {
    //setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_upd_system.php",
      data: { begdate: begdate, enddate: enddate },
    })
      .then(function (response) {
        if (response.data[0].statuscode === 1) {
          setOpenDate(false);
          setBodyMsgBox("บันทึกข้อมูลเรียบร้อย");
          setOpenMsgBox(true);
          setReload(reload + 1);
          
        } else {
          alert(response.data[0].message);
        }
        //setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        //setLoading(false);
      });
  }  

  const OkMsgBox = () => {
    if (bodyMsgBox === "ลงทะเบียนไลน์") {
      let URL = "https://notify-bot.line.me/oauth/authorize?";
      URL += "response_type=code";
      URL += "&client_id=ano4nF0LUR6MvTBUvguNeP";
      URL += "&redirect_uri=https://thaipuma.com/order/score_line_register.php"; //ถ้า login แล้ว เลือกกลุ่มหรือตัวเอง ให้กลับมาหน้านี้
      URL += "&scope=notify";
      URL += "&state=thaipuma";
      window.location = URL;
    }
    if (bodyMsgBox === "บันทึกข้อมูลเรียบร้อย") {
      setOpenMsgBox(false);
    }  

  };

  useEffect(() => {
    GetItem();
  }, [reload]);

  return (
    <>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem
          alignItems="flex-start"
          onClick={() => {
            setOpenDate(true);
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="ช่วงเวลาสะสมแต้มและแลกของรางวัล"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {begdate} ถึง {enddate}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem
          alignItems="flex-start"
          onClick={() => {
            setBodyMsgBox("ลงทะเบียนไลน์");
            setOpenMsgBox(true);
          }}
        >
          <ListItemAvatar>
            <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="การแจ้งเตือนเมื่อลูกค้ากดแลกรางวัล"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {lineToken}
                  <br />
                  {lineToken === ""
                    ? ""
                    : "พบข้อมูลการลงทะเบียนในระบบแล้ว หากลงทะเบียนกลุ่มใหม่ ผู้ใช้ปัจจุบันต้องเข้าร่วมกลุ่มใหม่อีกครั้ง"}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary="ล้างข้อมูล"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  ลบข้อมูลคะแนน เพื่อเริ่มสะสมใหม่
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>

      <Dialog open={openMsgBox} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleMsgBox}</DialogTitle>
        <DialogContent>
          <DialogContentText>{bodyMsgBox}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMsgBox(false);
            }}
            color="primary"
          >
            Cancle
          </Button>

          <Button onClick={OkMsgBox} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDate}
        onClose={null}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          ช่วงเวลาสะสมแต้มและแลกของรางวัล
        </DialogTitle>
        <DialogContent>
          <br/>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="เริ่มวันที่"
                variant="outlined"
                value={begdate}
                onChange={(e) => {
                  setBegdate(e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="date"
                label="ถึงวันที่"
                variant="outlined"
                value={enddate}
                onChange={(e) => {
                  setEnddate(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDate(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={UpdateItem} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
