import * as React from "react";
import { useState, useEffect } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Grid,
  Box,
  Fab,
  Button,
  Avatar,
} from "@mui/material";

import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";

import "./UserLogin.css";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import UserQR from "./UserQR";

const columnMember = [
  {
    field: "datecreate",
    headerName: "วันที่สมัคร",
    width: 160,
  },  
  {
    field: "custname",
    headerName: "ชื่อร้านค้า",
    width: 250,
  },
  {
    field: "custgroup",
    headerName: "ประเภท",
    width: 100,
    renderCell: (params) => (params.value === "1" ? "Dealer" : "Sub dealer"),
  },
  {
    field: "fullname",
    headerName: "ชื่อลูกค้า",
    width: 200,
  },
  {
    field: "sex",
    headerName: "เพศ",
    width: 50,
  },  
  {
    field: "birthday",
    headerName: "วันเกิด",
    width: 100,
  },  
  {
    field: "addr",
    headerName: "ที่อยู่",
    width: 250,
  },  
  {
    field: "tel",
    headerName: "โทรศัพท์",
    width: 100,
  },  
  {
    field: "email",
    headerName: "อีเมล์",
    width: 200,
  },   
  {
    field: "custpic",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },

  {
    field: "score",
    headerName: "คะแนนทั้งหมด",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },

  {
    field: "used",
    headerName: "คะแนนที่ใช้",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },

  {
    field: "bal",
    headerName: "คงเหลือ",
    type: "number",
    renderCell: (params) => Number(params.value).toLocaleString(),
  },
];

const columnWait = [
  {
    field: "datecreate",
    headerName: "วันที่แลกรางวัล",
    width: 160,
    //editable: true,
  },
  {
    field: "topic",
    headerName: "รายการ",
    width: 250,
  },
  {
    field: "pic",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },
  {
    field: "custname",
    headerName: "ชื่อร้านค้า",
    width: 200,
  },
  {
    field: "username",
    headerName: "ชื่อลูกค้า",
    width: 200,
  },
  {
    field: "addr",
    headerName: "ที่อยู่",
    width: 250,
  },
  {
    field: "tel",
    headerName: "โทรศัพท์",
    width: 100,
  },

  {
    field: "statusname",
    headerName: "สถานะ",
  },
  {
    field: "remark",
    headerName: "รายละเอียด",
    width: 250,
  },
];

const columnReturn = [
  {
    field: "datecreate",
    headerName: "วันที่คืน",
    width: 200,
    //editable: true,
  },
  {
    field: "warrantyno",
    headerName: "เลขรับประกัน",
    width: 200,
  },
  {
    field: "itemcode",
    headerName: "รหัสสินค้า",
    width: 200,
  },

  {
    field: "pic_name",
    headerName: "รูป",
    width: 60,
    //editable: true,
    renderCell: (params) => (
      <Avatar src={process.env.REACT_APP_URL + "/upload/" + params.value} />
    ), // renderCell will render the component
  },
  {
    field: "score",
    headerName: "คะแนน",
    width: 100,
  },
  {
    field: "scoredate",
    headerName: "วันที่สะสมแต้ม",
    width: 200,
  },
  {
    field: "custcode",
    headerName: "รหัสลูกค้า",
    width: 100,
  },
];

export default function UserHome() {
  const [UserID, setUserID] = useState("");
  const [Password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  

  const [openData, setOpenData] = useState(false);
  const [titleData, setTitleData] = useState("");

  const [arrData, setArrData] = React.useState([]);
  const [arrColumn, setArrColumn] = React.useState([]);

  // const [summaryData, setSummaryData] = React.useState([]);
  const [qtyMember, setQtyMember] = React.useState(0);
  const [qtyWait, setQtyWait] = React.useState(0);
  const [qtySend, setQtySend] = React.useState(0);
  const [qtySent, setQtySent] = React.useState(0);
  const [qtyReturn, setQtyReturn] = React.useState(0);

  const [loading, setLoading] = React.useState(false);


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: titleData,
           // delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={{
            fileName:titleData,
            hideFooter: true,
            hideToolbar: true,
          }}


        />
      </GridToolbarContainer>
    );
  }

  async function GetUser() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_get_user.php",
      data: { userid: UserID, password: Password },
      // headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //console.log(response.data);
        if (response.data[0].statuscode == 0) {
          setOpen(true);
        } else {
          window.location = "/usermain";
        }
        //setProvinces(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function ShowMember() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_member.php",
      data: {},
    })
      .then(function (response) {
        setArrColumn(columnMember);
        setOpenData(true);
        setArrData(response.data);
        setTitleData("สมาชิก");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function ShowRedeem(isStatus, isTitle) {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_redeem.php",
      data: { custname: "", fname: "", isstatus: isStatus },
    })
      .then(function (response) {
        //console.log(response);
        setArrColumn(columnWait);
        setOpenData(true);
        setArrData(response.data);
        setTitleData(isTitle);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function ShowReturn() {
    setLoading(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_all_return.php",
      data: {},
    })
      .then(function (response) {
        setArrColumn(columnReturn);
        setOpenData(true);
        setArrData(response.data);
        setTitleData("คืนแบต");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function GetSummary() {
    axios({
      method: "post",
      url: process.env.REACT_APP_URL + "/score_summary.php",
      //data: {},
    })
      .then(function (response) {

     // console.log(response.data);
        if (response.data[0].statuscode == 0) {
          //          setSummaryData(response.data)
          setQtyMember(response.data[0].member);
          setQtyWait(response.data[0].wait);
          setQtySend(response.data[0].send);
          setQtySent(response.data[0].sent);
          setQtyReturn(response.data[0].return);
        }
        //setProvinces(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    GetSummary();
    const onConfirmRefresh = function (event) {
      event.preventDefault();
      // return (event.returnValue = "Are you sure you want to leave the page?");
    };
    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
  }, []);

  return (
    <div>
      <Grid container spacing={15}>
        <Grid
          className="font-link"
          item
          sm={6}
          xs={12}
          sx={{
            textAlign: "center",
            //fontSize: "30pt",
            // backgroundColor: "#dedede",
            borderRadius: "20px",
          }}
          onClick={ShowMember}
        >
          <img
            src={process.env.REACT_APP_URL + "/upload/team.png"}
            width={150}
            style={{ borderRadius: 10 }}
            alt={"xxx"}
          />

          <Box sx={{ fontSize: "18pt" }}>สมาชิก {qtyMember} คน</Box>
        </Grid>

        <Grid
          className="font-link"
          item
          sm={6}
          xs={12}
          sx={{
            textAlign: "center",
            borderRadius: "20px",
          }}
          onClick={() => {
            ShowRedeem(1, "รอจัดส่ง");
          }}
        >
          <img
            src={process.env.REACT_APP_URL + "/upload/packages.png"}
            width={150}
            style={{ borderRadius: 10 }}
            alt={"xxx"}
          />

          <Box sx={{ fontSize: "18pt" }}>รอจัดส่ง {qtyWait} รางวัล</Box>
        </Grid>
      

        <Grid
          className="font-link"
          item
          sm={6}
          xs={12}
          sx={{
            textAlign: "center",
            borderRadius: "20px",
          }}
          onClick={() => {
            ShowRedeem(2, "กำลังจัดส่ง");
          }}
        >
          <img
            src={process.env.REACT_APP_URL + "/upload/delivery.png"}
            width={150}
            style={{ borderRadius: 10 }}
            alt={"xxx"}
          />

          <Box sx={{ fontSize: "18pt" }}>กำลังจัดส่ง {qtySend} รางวัล</Box>
        </Grid>


        <Grid
          className="font-link"
          item
          sm={6}
          xs={12}
          sx={{
            textAlign: "center",
            borderRadius: "20px",
          }}
          onClick={() => {
            ShowRedeem(2, "กำลังจัดส่ง");
          }}
        >
          <img
            src={process.env.REACT_APP_URL + "/upload/received.png"}
            width={150}
            style={{ borderRadius: 10 }}
            alt={"xxx"}
          />

          <Box sx={{ fontSize: "18pt" }}>จัดส่งแล้ว {qtySend} รางวัล</Box>
        </Grid>


        <Grid
          className="font-link"
          item
          sm={6}
          xs={12}
          sx={{
            textAlign: "center",
            borderRadius: "20px",
          }}
          onClick={ShowReturn}
        >
          <img
            src={process.env.REACT_APP_URL + "/upload/product-return.png"}
            width={150}
            style={{ borderRadius: 10 }}
          />

          <Box sx={{ fontSize: "18pt" }}>คืนแบต {qtyReturn} ลูก</Box>
        </Grid>
      </Grid>

      {/* ====================== Member ============================= */}
      <Dialog
        fullScreen
        open={openData}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenData(false);
              }}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {titleData}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="false" sx={{ paddingTop: "80px" }}>              
        <DataGrid
          rows={arrData}
          columns={arrColumn}
          slots={{
            toolbar: CustomToolbar,
          }}          

          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          disableRowSelectionOnClick
          loading={loading}

        />
      </Container>
        <br />
      </Dialog>


    </div>
  );
}
