import * as React from 'react';
import UserItem from './UserItem';
import UserReward from './UserReward';
import UserHome from './UserHome';
import UserMember from './UserMember';
import UserRedeem from './UserRedeem';
import UserReturn from './UserReturn';
import UserPro from './UserPro';
import UserSystem from './UserSystem';
import UserGroup from './UserGroup';
import UserScore from './UserScore';
import UserQR from './UserQR';



export default function UserMain(props) {


    

    if (props.index==='1000') {
        return <UserHome/>
    }
    if (props.index==='1010') {
        return <UserQR/>
    }    
    if (props.index==='1020') {
        return <UserMember/>
    }    
    if (props.index==='1030') {
        return <UserItem/>
    }
    if (props.index==='1040') {
        return <UserGroup/>
    }    
    if (props.index==='1050') {
        return <UserReward/>
    }
    if (props.index==='1060') {
        return <UserPro/>
    }    
    if (props.index==='1070') {
        return <UserScore/>
    }        
    if (props.index==='1080') {
        return <UserRedeem/>
    }
    if (props.index==='1090') {
        return <UserReturn/>
    }
    if (props.index==='1100') {
        return <UserSystem/>
    }

}    